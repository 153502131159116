import { useStaticQuery, graphql } from "gatsby";
import React from "react";

const ParksSocialTiesOnlinePage = () => {
    const data = useStaticQuery(graphql`
        query MyQueryParksOnline {
            file(relativePath: {eq: "Parks_Social_Ties_Online.pdf"}) {
                publicURL
                name
            }
        }
    `);
    return <head>
        <meta http-equiv="refresh" content={"0; URL=" + data.file.publicURL} />
        <title>The Contribution of Urban Parks to Social Ties between Neighborhoods.</title>
    </head>
  };
  
  export default ParksSocialTiesOnlinePage;